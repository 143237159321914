<template>
	<main>
		<div class="section s2">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box">
					<div class="content">
						<p>智算时代，随着千亿级参数大模型的不断涌现，多样性算力需求进一步增长，数据作为推动AI发展的核心生产要素，其所释放出的价值日益凸显，而数据规模及质量决定了AI智能的高度。</p>
						<p>作为算力价值发挥的基础，数据存储技术的不断演进，使得智能数据管理的性能及效率日益攀升。为着力夯实AI应用算力及存力底座，智能数据管理及中国企业数字化变革的赋能者，联想凌拓不断创新智能、强大和可信的数据管理解决方案，让企业应用更简便、更节约、更安全和可持续发展，助力企业实现AI时代的“存力”突围及AI就绪的未来。</p>
						<p>2024年1月23日，智能存储及数据管理行业盛会联想凌拓2024技术大会（INSIGHT CHINA 2024）将于深圳举办。届时，我们将邀请业界专家、媒体、客户与生态合作伙伴欢聚一堂，共同深入洞察数据要素载体的创新价值，分享智算时代下数据驱动AI应用的创新技术，携手驾驭日新月异的AI世界，并永立潮头！</p>
					</div>
					<div class="info">
						<div class="item">
							<div class="tit">会议时间:</div>
							<div class="text">2024 年1月23日</div>
						</div>
						<div class="item">
							<div class="tit">会议地点:</div>
							<div class="text">深圳鹏瑞莱佛士酒店·宴会厅二层 (深圳市南山区中心路粤海街道中心路3008号)</div>
						</div>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>
<script>
export default {

}
</script>

<style lang="scss" scoped>
.s2{color:#111111;position: relative;
	.section-wrap{text-align: center;position: relative;z-index: 10;
		.title-box{display: inline-block; width: 537px;height:134px; background: url("~@/assets/image/2024/t1.png") no-repeat top center;background-size: contain;	}
		.content-box{margin-top: 30px;
			.content{padding:50px 50px 0;font-size: 18px;line-height:40px;text-align: left;
				p{text-indent:40px;}
			}
			.info{text-align: left;padding: 0 50px;font-size: 18px;line-height:40px;
				.item{display: flex;
					.tit{margin-right: 15px;}
					.text{flex: 1;}
				}
				.item:nth-last-child(1){margin-bottom: 0;}
			}
		}
	}
}
.s2::after{content: "";display: block;position:absolute;top:150px;right:0;width: 540px;height:180px;background: url("~@/assets/image/2024/i1.png") no-repeat right top;background-size:contain;}
@media screen and (max-width: 1230px) {
	.s2{
		.section-wrap{
			.title-box{width: middle(537px);height:middle(134px); padding: middle(60px) 0 middle(5px);}
			.content-box{margin-top: middle(30px);
				.content{padding: middle(50px) middle(50px) 0;font-size: middle(18px);line-height:middle(40px);
					p{text-indent:middle(40px);}
				}
				.info{padding: 0 middle(50px);font-size: middle(18px);line-height:middle(40px);
					.item{
						.tit{margin-right: middle(15px);}
					}
				}
			}
		}
	}
	.s2::after{top:middle(150px);width: middle(540px);height:middle(180px);}
}
@media screen and (max-width: 992px) {
	.s2{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:67px;}
			.content-box{margin-top: 12px;
				.content{padding: 0;font-size: 14px;line-height:1.6;
					p{text-indent: 30px;}
				}
				.info{padding: 0;font-size: 14px;line-height:1.6;
					.item{
						.tit{margin-right: 7px;}
					}
				}
			}
		}
	}
	.s2::after{top:150px;width:270px;height:90px;}
}
</style>
