<template>
	<main>
		<div class="section s8">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box">
					<div class="list">
						<div class="item" v-for="(item,index) in media" :key="index">
							<div class="qrcode">
								<img :src="item.image" alt="">
							</div>
						</div>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>
<script>
export default {
	data(){
		return {
			media:[
				{title:"",image:require('@/assets/image/2024/logo-1.png')},
				//{title:"",image:require('@/assets/image/2024/logo-2.png')},
				{title:"",image:require('@/assets/image/2024/logo-3.png')},
				{title:"",image:require('@/assets/image/2024/logo-4.png')},
				{title:"",image:require('@/assets/image/2024/logo-5.png')},
			]
		}
	}
}
</script>
<style lang="scss" scoped>
.s8{padding-top: 60px;
	.section-wrap{text-align: center;position: relative;z-index: 2;
		.title-box{display: inline-block; width: 537px;height:134px; background: url("~@/assets/image/2024/t7.png") no-repeat top center;background-size: contain;}
		.content-box{margin-top: 60px;display: flex;justify-content: center;
			.list{display: flex;flex-wrap: wrap;
				.item{flex: 1;margin-right: 22px;display: flex;align-items: center;justify-content: center;
					.qrcode{width: 100%; background: #fff; padding: 10px;height:100px;display: flex;align-items: center;justify-content: center;
						img{width: 100%;height:100%; object-fit: contain;display: block;}
					}
				}
				.item:nth-last-child(1){margin-right: 0;}
			}
		}
	}
}
@media screen and (max-width: 1230px) {
	.s8{padding-top: middle(60px);
		.section-wrap{
			.title-box{width: middle(537px);height:middle(134px);}
			.content-box{margin-top: middle(60px);
				.list{
					.item{margin-right: middle(22px);
						.qrcode{padding: middle(10px);height:middle(100px);}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.s8{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:67px;}
			.content-box{margin-top: 15px;
				.list{justify-content: center;
					.item{flex:none;width: calc((100% - 20px)/3); margin-right: 10px;margin-bottom: 10px;
						.qrcode{padding: 5px;height:50px;}
					}
					.item:nth-child(1){width: 100%;margin-right: 0;
						.qrcode{width: calc((100% - 20px)/3);}
					}
				}
			}
		}
	}
}
</style>
